import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { TabletAndBelow, Desktop } from 'styles'

import HamburgerMenu from './HamburgerMenu'
import {
  Wrapper,
  LogoLink,
  NavWrapper,
  Link,
  Button,
} from './styled-components'

const Header = () => {
  const { pathname } = useLocation()

  if (pathname === '/terms' || pathname === '/privacy') {
    return null
  }

  const isHome = pathname === '/' || pathname === '/#top'

  const activePath = pathname.split('/')[1]

  return isHome ? (
    <Wrapper id="top">
      <LogoLink to="/#top">ryco.io</LogoLink>
      <TabletAndBelow>
        <HamburgerMenu />
      </TabletAndBelow>
      <Desktop>
        <NavWrapper>
          <Button
            to="https://www.app.ryco.io/auth/createAccount"
            target="_blank"
          >
            sign up
          </Button>
          <Link to="/services">services</Link>
          <Link to="/blog">blog</Link>
          <Link to="/about">about</Link>
          <Link to="/resourceHub">resources</Link>
          <Link target="_blank" to="https://www.app.ryco.io/auth/signIn">
            sign in
          </Link>
        </NavWrapper>
      </Desktop>
    </Wrapper>
  ) : (
    <Wrapper id="top" size="sm">
      <LogoLink size="sm" to="/#top">
        ryco.io
      </LogoLink>
      <TabletAndBelow>
        <HamburgerMenu />
      </TabletAndBelow>
      <Desktop>
        <NavWrapper size="sm">
          <Link size="sm" to="/">
            home
          </Link>
          <Link size="sm" to="/services" isActive={activePath === 'services'}>
            services
          </Link>
          <Link size="sm" to="/blog" isActive={activePath === 'blog'}>
            blog
          </Link>
          <Link size="sm" to="/about" isActive={activePath === 'about'}>
            about
          </Link>
          <Link
            size="sm"
            to="/resourceHub"
            isActive={activePath === 'resourceHub'}
          >
            resources
          </Link>
          <Link
            size="sm"
            target="_blank"
            to="https://www.app.ryco.io/auth/signIn"
          >
            sign in
          </Link>
          <Button
            size="sm"
            to="https://www.app.ryco.io/auth/createAccount"
            target="_blank"
          >
            sign up
          </Button>
        </NavWrapper>
      </Desktop>
    </Wrapper>
  )
}

export default Header
