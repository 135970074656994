const data = [
  {
    title: 'The Platform Overview',
    video:
      'https://drive.google.com/file/d/1xfhk3KpPhqwjYGg-YtPAI8PaXFjVhYLg/preview',
    details: [
      {
        title: 'Welcome to Your ryco.io Homepage',
        description:
          "When you log in, you'll land on your ryco.io homepage, designed as a central hub where you can access all the main features and tools. Take a moment to familiarize yourself with the layout, as this will be your starting point for everything on the platform.",
      },
      {
        title: 'How Do I Access My Main Features?',
        description:
          'From the homepage, you can find links to all the primary tools available to you at the top of the screen. Simply click on the dashboard icons or menu items to explore resources, request new assets, or manage your projects efficiently.',
      },
      {
        title: 'Where Can I Find My Dashboard?',
        description:
          'Your dashboard is located on the homepage and provides an overview of your current projects, requested projects and their status, recent activity, and quick links to essential features. It’s organized to keep your tasks and resources easily accessible in one place.',
      },
      {
        title: 'How Do I Request New Assets?',
        description:
          'To request new assets, click the "Request New Asset" button. From there, you’ll be prompted to choose asset types, add specific details like names, objectives, and due dates, and submit the request. The process is designed to make customization simple and clear.',
      },
      {
        title: 'Are There Additional Tools I Can Use?',
        description:
          'In addition to your main resources, ryco.io offers various tools for deeper customization and engagement. You can find these additional options on the top of the screen and find the “Chat” and “Collaborate sections.',
      },
      {
        title: 'Any Tips for Navigating the Platform?',
        description:
          'To get the most out of the platform, explore the options in your main navigation bar and use the dashboard as your command center. Familiarize yourself with these features to streamline your workflow.',
      },
    ],
  },
  {
    title: 'Getting Started with Asset Requests',
    video:
      'https://drive.google.com/file/d/1XFuxaLUvz9N5FXaj3Ugw7nhHdhd2a6wq/preview',
    details: [
      {
        title: 'Choosing the Asset Type',
        description:
          'In the form, select the type of asset you need, such as an interactive element, video, or graphic, depending on your requirements.',
      },
      {
        title: 'Adding Specific Details',
        description:
          'Enter essential details for the asset, including the name, unit and lesson numbers, and due date. Providing clear information ensures efficient processing. In the description section, outline your vision and ideas for the asset. If you need help with a description, refer to the “Utilizing rybot to Request an Asset” section.',
      },
      {
        title: 'Describing Your Objectives',
        description:
          'Use the objectives section to outline learning goals or desired outcomes, helping the team align the content with your educational needs. If any specific frameworks are required, please include those for precise alignment.',
      },
      {
        title: 'Submitting Supporting Information',
        description:
          'Attach any additional resources, links, or specific requirements in the supporting information section. This may include reference documents or anything that helps align the request with your intended goals.',
      },
      {
        title: 'Final Submission',
        description:
          'Once all fields are complete, review your request and submit it. Your asset request will appear in your project dashboard, where you can track its progress. If you’re not ready to submit, you can “Save as Draft,” which will keep it in draft status on your dashboard until you’re ready to submit officially. This feature helps you plan and organize future requests as they arise.',
      },
    ],
  },
  {
    title: 'How To: Chat (AI Teaching Assistant “rybot”)',
    video:
      'https://drive.google.com/file/d/1tqbddPjNRy_7_fN-lxcZiKQJeGk3GI-m/preview',
    details: [
      {
        title: 'Getting Started with rybot',
        description:
          'Access rybot from your ryco.io dashboard by opening the chat window to start interacting.',
      },
      {
        title: 'Asking Questions',
        description:
          'Type your question or request in the chat box. rybot can assist with various tasks, from lesson planning to activity ideas for different grades and subjects. Be specific for the best results.',
      },
      {
        title: 'Creating Lesson Plans',
        description:
          'Describe the topic, grade level, and any specific goals for a structured lesson plan with objectives, activities, and key points.',
      },
      {
        title: 'Generating Classroom Activities',
        description:
          'To brainstorm activities, specify the subject or theme. You can also request ideas for different learning styles or age groups to keep students engaged.',
      },
      {
        title: 'Adapting Content for Diverse Learners',
        description:
          'For adaptations for specific needs, such as resonaging with those with various disabilities, mention these requirements. rybot will offer tailored suggestions to make learning inclusive.',
      },
      {
        title: 'Translating Content',
        description:
          'Ask rybot to translate lesson materials or activities by specifying the target language.',
      },
      {
        title: 'Saving and Using Responses',
        description:
          'Copy and paste rybot’s responses directly into your materials or save them. The chat history keeps a record for easy reference.',
      },
    ],
    additional: [
      {
        title: 'Tips for Effective Use',
        data: [
          'Be specific: More details yield more tailored responses.',
          'Experiment with prompts: Try different questions to explore rybot’s full capabilities.',
          'Review and customize: Use or further adapt rybot’s suggestions to fit your style.',
          'Be specific: The more details you provide, the more tailored and relevant rybot’s responses will be.',
          'Experiment with prompts: Try different ways of asking questions to explore rybot’s full range of capabilities.',
          'Review and customize: rybot’s suggestions can be used as-is or customized further to suit your teaching style.',
          'Use follow-up questions: If the initial response isn’t exactly what you need, ask rybot follow-up questions for clarification or more detail.',
          'Combine prompts for complex tasks: Break down larger tasks into smaller prompts, then combine the results for a cohesive outcome.',
          'Try different phrasing: Reword questions if you’re not getting the response you want; sometimes a small change in phrasing can yield better results.',
          'Use examples: When asking for help on specific activities or lessons, include examples of what you’re looking for to provide rybot with context.',
          'Save frequently used prompts: Keep a list of prompts you find effective, so you can reuse or adapt them for similar tasks in the future.',
          'Encourage creativity: Ask rybot for unique, creative approaches to topics or activities to add variety to your lessons.',
          'Request summaries or outlines: If you need quick overviews, ask rybot to summarize complex content or provide outlines to simplify planning.',
          'Set clear objectives: Start with the end goal in mind and communicate this in your prompts to help rybot provide more aligned responses.',
        ],
      },
    ],
  },
  {
    title: 'How To: Collaborate (Digital Campus “ryverse”)',
    video:
      'https://drive.google.com/file/d/1fG9qgyx_JpdHQENh5BZMkADp1LsQgSRW/preview',
    details: [
      {
        title: 'Entering the ryverse',
        description:
          'To start, navigate to the “Collaborate” tab that will bring you to the ryverse portal on the ryco.io platform. After logging in, you will be able to choose and customize an avatar that represents you in this interactive environment.',
      },
      {
        title: 'Exploring the Virtual Campus',
        description:
          'Once inside, you’ll find a virtual campus designed for meetings, collaboration, and exploration. Use the arrow controls or right click to navigate and explore various spaces.',
      },
      {
        title: 'Starting a Meeting &  Interactive Features for Engagement',
        description:
          'To initiate a session, go into any meeting areas, or simply join a bubble of others. Privacy features allow you to control access, ensuring secure discussions by pressing the spacebar to either lock your bubble, or close the meeting room door. Each meeting window includes tools for screen sharing, chat, polls, and multimedia presentations.',
      },
    ],
    additional: [
      {
        title: 'Use Cases for the ryverse',
        data: [
          'Virtual Webinar: Host live virtual webinars with interactive elements, engaging others regardless of location.',
          'Collaboration: Use it as a collaborative space for educators to share resources and discuss strategies.',
          'Consultation Meetings: Conduct private, secure virtual meetings with us or others.',
        ],
      },
      {
        title: 'Tips for Using the ryverse Effectively',
        data: [
          'Maximize engagement: You’re encouraged to explore the space to become comfortable in the virtual environment.',
          'Ensure privacy: Use privacy settings to control access, ensuring secure, focused sessions.',
        ],
      },
    ],
  },
  {
    title: 'Tips For Utilizing rybot With Asset Request',
    video:
      'https://drive.google.com/file/d/1dxZ6WtAEzrIt5VLv9WFGLWJA4E8SZUu8/preview',
    details: [
      {
        title: 'Brainstorming Asset Ideas',
        description:
          'Start by asking rybot to help you brainstorm. If you’re unsure of what type of asset would work best for your lesson or project, type in a general topic or objective and ask for asset ideas. rybot will suggest creative options tailored to your needs, from interactive videos to engaging graphics.',
      },
      {
        title: 'Drafting Your Asset Description',
        description:
          'Once you’ve decided on an asset, create an initial description that outlines what you need. This can include key points, objectives, or any specific features you want included. if you need assistance refining your description, rybot can help with that, too.',
      },
      {
        title: 'Refining Your Description',
        description:
          'To ensure your description is clear and professional, ask rybot to “make this description easier to understand” or “improve the wording for an asset request.” rybot will provide a polished version that you can copy and paste into the description section of your asset request.',
      },
      {
        title: 'Submitting Your Request',
        description:
          'With your refined description, head to the asset request form and paste it into the appropriate section. Complete any additional fields, such as asset type or due date, and submit your request, or save it as a draft.',
      },
    ],
  },
  {
    title: '11/11 Platform Updates',
    video:
      'https://drive.google.com/file/d/1vOgNg43VOgI-Hu0NbmFy1t7_k9HaBPt4/preview',
    details: [
      {
        title: 'Drafted Asset Requests',
        description:
          'You can now save asset requests as drafts. This option is perfect if you’re not ready to submit an asset request or want to plan for future projects without finalizing them. When creating a new asset request, simply select the “Draft” option to save your progress without submitting it.',
      },
      {
        title: 'Additional Asset Request Type',
        description:
          'A new "Other" category has been added for asset request types. Previously, you could only choose between map, interactive, or video. Now, if your asset doesn’t fit into these categories, you can select "Other" for more flexibility. When creating an asset request, select "Other" in the asset type dropdown if your request doesn’t fit the predefined categories.',
      },
      {
        title: 'Full Asset Request View from the Card',
        description:
          'You can now view your complete asset request directly from the card view, including all deadlines and project details. On your dashboard, select the card for any asset request to see a comprehensive view of your submission.',
      },
      {
        title: 'Document Uploads in rybot',
        description:
          'rybot now supports document uploads, allowing you to attach documents, spreadsheets, and more directly to your prompts, so rybot can analyze, assess, and generate your content and much more based on your existing materials.',
      },
      {
        title: 'Option to Include/Exclude Unit and Lesson Numbers',
        description:
          'You now have the option to add or omit unit and lesson numbers in asset requests, which is especially useful for projects that don’t require these details. When creating an asset request, look for the option to include or exclude unit and lesson numbers as needed.',
      },
      {
        title: 'Resource Hub Access',
        description:
          'We’ve streamlined access to the Resource Hub, which now includes FAQs, onboarding guides, tips, and tricks to help you maximize the platform’s features. From your dashboard homepage, click the “Resource Hub” link under the onboarding and tips section to access all resources.',
      },
      {
        title: 'Navigation Shortcut to Blog',
        description:
          'A new navigation shortcut in the ryverse under the “Collaborate” tab allows you to quickly access the ryco.io blog. Hover over the ryco.io logo or driver icons and press the spacebar to go directly to the blog, where you’ll find the latest updates and insights from our team.',
      },
    ],
  },
  {
    title: '1/12 Platform Updates',
    video:
      'https://drive.google.com/file/d/1tE3uh4DbPIJR5x8mF9Sf137y-36QgxiG/preview',
    details: [
      {
        title: 'Profile Picture Upload Feature',
        description:
          'Personalize your experience by uploading a profile picture directly to your account. Simply navigate to your profile settings, click "Edit," and select a photo from your computer. It’s a small step that makes your digital workspace feel more like home.',
      },
      {
        title: 'Drag-and-Drop File Uploads',
        description:
          'Simplify your workflow with our new drag-and-drop feature. You can now easily upload files directly from your computer into the platform, saving you time and keeping everything organized in one place.',
      },
      {
        title: '"Ask rybot" Fully Integrated into Asset Requests',
        description:
          'Crafting personalized classroom content just got easier. The “Ask rybot” feature is now integrated into the asset request process, allowing you to copy and paste AI-generated suggestions seamlessly. Whether you need lesson plans, translations, or custom ideas, rybot is ready to assist.',
      },
      {
        title: 'Communications Board Within Your Requests',
        description:
          'Stay connected with our new communications board, built right into your asset requests. Collaborate with our team, track progress, and leave feedback—all within a centralized space. This feature ensures you’re always aligned on deliverables and timelines.',
      },
    ],
  },
]

export default data.reverse()
