import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import './HamburgerMenu.css'

import Facebook from 'assets/images/socialIcons/Facebook.png'
import Instagram from 'assets/images/socialIcons/Instagram.png'
import LinkedIn from 'assets/images/socialIcons/LinkedIn.png'
import Twitter from 'assets/images/socialIcons/Twitter.png'
import { linkedIn, facebook, instagram, twitter } from 'constants/links'
import { H } from 'highlight.run'
import { colors } from 'styles'

import {
  Wrapper,
  NavContainer,
  SocialsFooter,
  SocialIcon,
  NavItem,
} from './styled-components'

const HamburgerMenu = () => {
  const isHome = useLocation().pathname === '/'

  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    //close the menu when a user clicks on a link
    const links = document.querySelectorAll('.nav a')
    links.forEach((link) => {
      link.addEventListener('click', () => {
        setIsOpen(false)
      })
    })
  }, [])

  return (
    <>
      {/* This changes the header bar color on mobile when the menu is open. */}
      <Helmet>
        <meta name="theme-color" content={'#7405CC'} />
      </Helmet>
      <Wrapper>
        <input
          id="toggle"
          type="checkbox"
          onChange={(e) => setIsOpen(e.target.checked)}
          checked={isOpen}
        ></input>
        <label
          htmlFor="toggle"
          className="hamburger"
          style={
            isOpen
              ? {
                  position: 'absolute',
                  zIndex: 100,
                }
              : {
                  position: 'inherit',
                  marginRight: isHome ? '30px' : '18px',
                  marginTop: isHome ? '32px' : '0px',
                  display: 'block',
                }
          }
        >
          <div className="top-bun"></div>
          <div className="meat"></div>
          <div className="bottom-bun"></div>
        </label>

        <div className="nav">
          <div className="nav-wrapper">
            <NavContainer>
              <NavItem
                to="https://www.app.ryco.io/auth/createAccount"
                target="_blank"
              >
                SIGN UP
              </NavItem>
              <NavItem to="/">HOME</NavItem>
              <NavItem to="/services">SERVICES</NavItem>
              <NavItem to="/blog">BLOG</NavItem>
              <NavItem to="/about">ABOUT</NavItem>
              <NavItem to="/resourceHub">RESOURCES</NavItem>
              <NavItem to="https://www.app.ryco.io/auth/signIn" target="_blank">
                SIGN IN
              </NavItem>
            </NavContainer>
            <SocialsFooter>
              <SocialIcon src={Facebook} alt="Facebook" href={facebook} />
              <SocialIcon src={Twitter} alt="Twitter" href={twitter} />
              <SocialIcon src={Instagram} alt="Instagram" href={instagram} />
              <SocialIcon src={LinkedIn} alt="LinkedIn" href={linkedIn} />
            </SocialsFooter>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default HamburgerMenu
