import { Link as DefaultLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'
import { colors } from 'styles'

export const Wrapper = styled.div<{ size?: string }>`
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 97px;

  ${({ size }) =>
    size === 'sm' &&
    css`
      background-color: ${colors.black};
      height: 60px;
      align-items: center;
    `}
`

export const LogoLink = styled(DefaultLink)<{ size?: string }>`
  font-family: 'Open Sans';
  text-decoration: none;
  color: ${colors.white};
  margin-left: 28px;
  margin-top: 30px;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 56px */
  letter-spacing: -0.4px;
  display: inline-block;
  position: absolute;
  z-index: 2;

  ${up('lg')} {
    font-size: 40px;
    margin-left: 122px;
    margin-top: 41px;
  }

  ${({ size }) =>
    size === 'sm' &&
    css`
      margin: 0 18px !important;
      font-size: 30px !important;
    `}
`

export const Link = styled(HashLink)<{ size?: string; isActive?: boolean }>`
  text-decoration: none;
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 17.6px */
  text-transform: lowercase;
  color: ${({ isActive }) =>
    isActive ? colors.lightPurpleHover : colors.white};
  display: flex;
  align-items: center;

  ${({ size }) =>
    size === 'sm' &&
    css`
      font-size: 14px !important;
      font-weight: 500 !important;

      &:hover {
        color: ${colors.lightPurpleHover};
      }
    `}
`

export const NavWrapper = styled.nav<{ size?: string }>`
  margin-top: 40px;
  margin-right: 40px;
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 20px;
  position: absolute;
  z-index: 2;
  right: 0;

  ${({ size }) =>
    size === 'sm' &&
    css`
      margin-top: 0px;
      margin-right: 18px;
    `}
`

export const Button = styled(Link)`
  background-color: ${colors.rycoPurple};
  color: ${colors.white};
  border-radius: 100px;
  height: 36px;

  align-items: center;
  display: flex;
  border: none;

  cursor: pointer;
  align-self: center;
  justify-content: center;
  padding: 0px 18px;
  margin-right: 30px;

  &:hover {
    color: ${colors.white};
  }

  ${({ size }) =>
    size === 'sm' &&
    css`
      margin-right: 0px;
    `}
`
