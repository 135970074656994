import React from 'react'

import bbm from 'assets/images/customers/bbm.svg'
import bios from 'assets/images/customers/bios.svg'
import bridgeway from 'assets/images/customers/bridgeway.svg'
import capstone from 'assets/images/customers/capstone.svg'
import cca from 'assets/images/customers/cca.svg'
import cde from 'assets/images/customers/cde.svg'
import cengage from 'assets/images/customers/cengage.svg'
import dgo from 'assets/images/customers/dgo.svg'
import discover from 'assets/images/customers/discover.svg'
import dk from 'assets/images/customers/dk.svg'
import ed3dao from 'assets/images/customers/ed3dao.svg'
import erc from 'assets/images/customers/erc.svg'
import erudyte from 'assets/images/customers/erudyte.svg'
import highlights from 'assets/images/customers/highlights.svg'
import jump from 'assets/images/customers/jump.svg'
import k20 from 'assets/images/customers/k20.svg'
import lead180 from 'assets/images/customers/lead180.svg'
import lerner from 'assets/images/customers/lerner.svg'
import lifeway from 'assets/images/customers/lifeway.svg'
import loyolapress from 'assets/images/customers/loyolapress.svg'
import lti from 'assets/images/customers/lti.svg'
import mv from 'assets/images/customers/mv.svg'
import natgeo from 'assets/images/customers/natgeo.svg'
import nordcompo from 'assets/images/customers/nordcompo.svg'
import readychapter1 from 'assets/images/customers/readychapter1.svg'
import rrr from 'assets/images/customers/rrr.svg'
import schoolspecialty from 'assets/images/customers/schoolspecialty.svg'
import tcm from 'assets/images/customers/tcm.svg'
import theupperroom from 'assets/images/customers/theupperroom.svg'
import tp from 'assets/images/customers/tp.svg'
import vista from 'assets/images/customers/vista.svg'
import yale from 'assets/images/customers/yale.svg'

import {
  Container,
  Header,
  ServicesContainer,
  ServiceContainer,
  ServiceImage,
} from './styled-components'

const customers = [
  // {
  //   image: yale,
  // },
  {
    image: natgeo,
  },
  {
    image: highlights,
  },
  {
    image: cengage,
  },

  // {
  //   image: dgo,
  // },

  // {
  //   image: lerner,
  // },
  // {
  //   image: tcm,
  // },
  // {
  //   image: jump,
  // },
  // {
  //   image: capstone,
  // },
  {
    image: lti,
  },
  {
    image: cca,
  },
  {
    image: tp,
  },
  {
    image: erc,
  },
  // {
  //   image: dk,
  // },
  {
    image: bbm,
  },
  {
    image: bridgeway,
  },
  {
    image: bios,
  },
  {
    image: mv,
  },
  // {
  //   image: rrr,
  // },
  // {
  //   image: vista,
  // },
  // {
  //   image: loyolapress,
  // },
  // {
  //   image: lifeway,
  // },
  // {
  //   image: k20,
  // },
  // {
  //   image: ed3dao,
  // },
  // {
  //   image: lead180,
  // },
  // {
  //   image: nordcompo,
  // },
  {
    image: erudyte,
  },
  {
    image: schoolspecialty,
  },
  {
    image: readychapter1,
  },
  {
    image: theupperroom,
  },
  // {
  //   image: cde,
  // },
  {
    image: discover,
  },
]

const Customers = ({ all }: { all?: boolean }) => {
  const data = all ? customers : customers.slice(0, 12)
  return (
    <Container>
      <Header id="services">
        {all ? 'Happy Customers' : 'Some of our happy customers'}
      </Header>

      <ServicesContainer>
        {data.map((service, idx) => (
          <Customer key={idx} service={service} />
        ))}
      </ServicesContainer>
    </Container>
  )
}

const Customer = ({ service }: { service: (typeof customers)[0] }) => {
  const { image } = service
  return (
    // <ServiceContainer>
    <ServiceImage alt={'customer logo'} src={image} />
    // </ServiceContainer>
  )
}

export default Customers
