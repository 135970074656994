import * as React from 'react'
import { useTrackingCode } from 'react-hubspot-tracking-code-hook'

import BackgroundOverlay from 'components/BackgroundOverlay'
import JoinTheTeam from 'components/JoinTheTeam'
import ScrollText from 'components/ScrollText'
import ScrollToTopButton from 'components/ScrollToTopButton'
import Testimonials from 'components/Testimonials'

import AboutUs from './AboutUs'
import Blog from './Blog'
import Customers from './Customers'
import LDTeams from './LDTeams'
import SassConvert from './SassConvert'
import ValueProp from './ValueProp'
import { Header, SubHeader } from './styled-components'

const Home = () => {
  const { setPathPageView } = useTrackingCode()

  React.useEffect(() => {
    setPathPageView('/home')
  }, [setPathPageView])

  return (
    <>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ScrollToTopButton />
        <Header>
          Welcome to <br />
          the future of
          <br />
          education
        </Header>
        <SubHeader>
          Save time, boost engagement, and bring tailored
          <br />
          learning to each classroom with innovative AI-driven
          <br />
          tools
        </SubHeader>

        <BackgroundOverlay />
        <ScrollText />
      </div>

      {/* TODO: refactor but for now this saves us from the scroll text overflowing */}
      <div style={{ overflow: 'hidden' }}>
        <ValueProp />
        <Customers />
        {/* <LDTeams /> */}
        {/* <AboutUs /> */}
        {/* <Blog /> */}
        {/* <Testimonials /> */}
        {/* <JoinTheTeam /> */}
        <SassConvert />
      </div>
    </>
  )
}

export default Home
